<template>
  <h1>404</h1>
</template>

<script>
export default {
  mounted() {
    window.location.href = process.env.VUE_APP_REDIRECT_URL ? process.env.VUE_APP_REDIRECT_URL : 'https://app.club-global.com';
  }
}
</script>
